import { genSaltSync, hashSync } from "bcrypt-ts";

// Register
export const encryptedBase64Password = (password: any) => {
  var base64EncryptedPassword = password;
  for (var i = 0; i < 5; i++) {
    base64EncryptedPassword = btoa(base64EncryptedPassword);
  }
  return base64EncryptedPassword;
};

export const decryptBase64Password = (base64EncryptedPassword: any) => {
  var base64DecryptedPassword = base64EncryptedPassword;
  for (var i = 0; i < 5; i++) {
    base64DecryptedPassword = atob(base64DecryptedPassword);
  }
  return base64DecryptedPassword;
};

// Login with # password
export const encryptedBcryptPassword = (password: string): string => {
  const salt: string = genSaltSync(10);
  const hashPassword: string = hashSync(password, salt);
  return hashPassword;
};

export const storeUserDatas = (userData: any) => {
  console.log("The stored data was", userData);
  localStorage.setItem("id", userData["id"]);
  localStorage.setItem(
    "customer_type",
    userData["userDetails"]["customerType"]
  );
  localStorage.setItem("company_name", userData["userDetails"]["companyName"]);
  localStorage.setItem("name", userData["userDetails"]["name"]);
  localStorage.setItem("mobile", userData["userDetails"]["mobile"]);
  localStorage.setItem("email", userData["userDetails"]["email"]);
  localStorage.setItem("address", userData["userDetails"]["address"]);
  localStorage.setItem(
    "kyc_document_id",
    userData["userDetails"]["kycDocumentId"]
  );
  localStorage.setItem("gst_number", userData["userDetails"]["gstNumber"]);
  localStorage.setItem("notes", userData["userDetails"]["notes"]);
  localStorage.setItem("token", userData["tokenresponse"]);
  localStorage.setItem("customerId", userData.userDetails.customerId);
  return localStorage;
};

export const removeUserDetails = () => {
  localStorage.removeItem("id");
  localStorage.removeItem("customer_type");
  localStorage.removeItem("company_name");
  localStorage.removeItem("name");
  localStorage.removeItem("mobile");
  localStorage.removeItem("email");
  localStorage.removeItem("address");
  localStorage.removeItem("address2");
  localStorage.removeItem("country");
  localStorage.removeItem("state");
  localStorage.removeItem("city");
  localStorage.removeItem("last_name");
  localStorage.removeItem("pin_code");
  localStorage.removeItem("kyc_document_id");
  localStorage.removeItem("gst_number");
  localStorage.removeItem("created");
  localStorage.removeItem("token");

  return localStorage;
};

export const commonBaseUrl = () => {
  // const baseURL = `https://dev-cw-api.equadriga.in/`; // Dev
  // const baseURL = `https://preprod-api.workfromhaven.in/`; // Pre
  const baseURL = `https://uat-wfh-api.equadriga.in/`; // UAT

  return baseURL;
};

export const commonImageUrl = () => {
  // const imageURL = `https://cws-dev-stor.equadriga.in/`; // Dev
  // const imageURL = `https://preprod-stor.workfromhaven.in/`; // Pre
  const imageURL = `https://uat-wfh-stor.equadriga.in/`; // UAT

  return imageURL;
};

export const razorPayKey = () => {
  // const key = "rzp_live_4F8l66yav7re7o"; // Live
  const key = "rzp_test_FVfksjf39F4WRs"; //Demo
  return key;
};

export const razorPaySecret = () => {
  // const key_secret = "BLCEDjEBdn9HSw4VPT1204Lt"; // Live
  const key_secret = "VENXGoeftb5J8FbPyT5DDuJh"; //Demo
  return key_secret;
};
